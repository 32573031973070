import { DateValidator } from './date.validator';

export class DateOnlyConverter {
  constructor() {}

  static convertJsDateToDateString(jsDate: Date): string {
    if (jsDate !== null && jsDate instanceof Date && DateValidator.isValidDate(jsDate)) {
      const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
      const day = jsDate.getDate().toString().padStart(2, '0');
      const year = jsDate.getFullYear().toString();
      const dateString = `${year}-${month}-${day}`;

      return dateString;
    }
    return '';
  }
}
