import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';
import { Store } from '@ngrx/store';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { SettingsModalActions, SettingsPageActions } from '../settings/state/settings.actions';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { SelfCertificationSettingsModel } from '../settings/models/self-report-settings.model';
import { selectSelfCertificationSettings } from '../about/state/organization-details.selectors';
import { DefaultButtonsModule } from '@mentor-one-ui/core/components/default-buttons/default-buttons.module';
import { OrganizationSettingsSelectors } from '../settings/state/settings.selectors';
import { InputNumberModule } from 'primeng/inputnumber';
import { InfoBoxComponent } from "../../../core/components/info-box/info-box.component";
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { SelectButtonModule } from 'primeng/selectbutton';

@Component({
  selector: 'mo-self-report-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    InputNumberModule,
    CheckboxModule,
    DefaultButtonsModule,
    InfoBoxComponent,
    SelectButtonModule,
],
  styleUrl: './self-certification-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <form [formGroup]="form" *ngIf="SelfCertificationSettings$ | async as settings">
        <div class="header">
          <h3>{{ 'self-report-settings-heading' | translate | async }}</h3>
          <p>{{ 'self-report-settings-sub-heading' | translate | async }}</p>
        </div>
        
        <p-selectButton
          [options]="selfReportOptions"
          (onChange)="selfReportOptionsChanged($event)"
          optionLabel="name"
          optionValue="value"
          formControlName="SelfCertificationOptions"
          class="selectButton">
            <ng-template let-item pTemplate>
              <i class="selectbutton-icon {{ form.get('SelfCertificationOptions')?.value == item.value ? 'fal fa-check-circle' : 'fal fa-circle' }}"></i>
              <span class="label">{{item.name}}</span>
            </ng-template>
        </p-selectButton>

        <ng-container [ngSwitch]="form.get('SelfCertificationOptions')?.value">
          <ng-container *ngSwitchCase="0">
            <div class="info-text" [innerHTML]="'self-report-settings-info-text-minimum-requirements' | translate | async | safeHtml"></div>
          </ng-container>
          <ng-container *ngSwitchCase="1">
            <div class="info-text" [innerHTML]="'self-report-settings-info-text-ia-agreement' | translate | async | safeHtml"></div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="info-text" [innerHTML]="'self-report-settings-info-text-custom' | translate | async | safeHtml"></div>
          </ng-container>
        </ng-container>

        <div class="content">
          <h3>{{ 'self-report-settings-what-to-limit' | translate | async }}</h3>
          <p-selectButton
            (onChange)="toggleLimitNumberOfOccurrences()"
            [options]="limitNumberOfOccurrences"
            optionLabel="name"
            optionValue="value"
            formControlName="SelfCertificationLimitNumberOfOccurrences">
            <ng-template let-item pTemplate>
              <i class="selectbutton-icon {{ form.get('SelfCertificationLimitNumberOfOccurrences')?.value == item.value ? 'fal fa-check-circle' : 'fal fa-circle' }}"></i>
              <span class="label">{{item.name}}</span>
            </ng-template>
          </p-selectButton>
          <ng-container [ngSwitch]="form.get('SelfCertificationLimitNumberOfOccurrences')?.value">
            <ng-container *ngSwitchCase="false">
              <fieldset class="pbl-grid">
                <div class="pbl-grid-largest">
                  <legend>{{ 'self-report-days' | translate | async }}</legend>
                  <p>{{ 'self-report-max-days-per-year' | translate | async }}</p>
                  <p-inputNumber
                    id="SelfCertificationMaxDaysPerYear"
                    formControlName="SelfCertificationMaxDaysPerYear"
                    [min]="12"
                    [showButtons]="true"
                    optionLabel="name"
                    optionValue="value"
                    appendTo="body"
                  >
                  </p-inputNumber>
                </div>
              </fieldset>

              <fieldset class="pbl-grid">
                <div class="pbl-grid-largest">
                  <legend>{{ 'self-report-days-per-period' | translate | async }}</legend>
                  <p>{{ 'self-report-max-days-per-period' | translate | async }}</p>
                  <p-inputNumber
                    id="SelfCertificationMaxDaysPerPeriod"
                    formControlName="SelfCertificationMaxDaysPerPeriod"
                    [min]="3"
                    [showButtons]="true"
                    optionLabel="name"
                    optionValue="value"
                    appendTo="body"
                  >
                  </p-inputNumber>
                </div>
              </fieldset>
            </ng-container>
            
            <ng-container *ngSwitchCase="true">
              <fieldset class="pbl-grid">
                <div class="pbl-grid-largest">
                <legend>{{ 'self-report-occurrences' | translate | async }}</legend>
                  <p>{{ 'self-report-occurrences-per-12-months' | translate | async }}</p>
                  <p-inputNumber
                    id="SelfCertificationOccurencesPer12Months"
                    formControlName="SelfCertificationOccurencesPer12Months"
                    [min]="4"
                    [showButtons]="true"
                    optionLabel="name"
                    optionValue="value"
                    appendTo="body"
                  >
                  </p-inputNumber>
                </div>
              </fieldset>

              <fieldset class="pbl-grid">
                <div class="pbl-grid-largest">
                  <legend>{{ 'self-report-days-per-occurrence' | translate | async }}</legend>
                  <p>{{ 'self-report-max-days-per-occurrence' | translate | async }}</p>
                  <p-inputNumber
                    id="SelfCertificationMaxDaysPerOccurence"
                    formControlName="SelfCertificationMaxDaysPerOccurence"
                    [min]="3"
                    [showButtons]="true"
                    optionLabel="name"
                    optionValue="value"
                    appendTo="body"
                  >
                  </p-inputNumber>
                </div>
              </fieldset>
            </ng-container>
          </ng-container>
        </div>
          
        <div class="p-dialog-footer p-dialog-footer--sticky">
          <mo-default-buttons
            [showDelete]="false"
            [canSave]="form.valid && hasChanges"
            [isSaving]="(isSaving$ | async)!"
            cancelLabel="{{ 'close' | translate | async }}"
            (cancel)="cancel()"
            (save)="save()"
          ></mo-default-buttons>
        </div>
      </form>
    </div>
  `,
})
export class SelfCertificationModalComponent implements OnInit, OnDestroy {
  SelfCertificationSettings$: Observable<SelfCertificationSettingsModel | null> = this.store
    .select(selectSelfCertificationSettings)
    .pipe(
      tap((t) => {
        this.form.patchValue(t);

        if(t.SelfCertificationLimitNumberOfOccurrences)  {
          if(t.SelfCertificationMaxDaysPerOccurence == 3 && t.SelfCertificationOccurencesPer12Months == 4) {
            this.form?.get('SelfCertificationOptions')?.setValue(0);
            this.selfReportOptionsChanged({value: 0});
          } else {
            this.form?.get('SelfCertificationOptions')?.setValue(2);
            this.selfReportOptionsChanged({value: 2});
          }
        } else {
          if(t.SelfCertificationMaxDaysPerPeriod == 8 && t.SelfCertificationMaxDaysPerYear == 24) {
            this.form?.get('SelfCertificationOptions')?.setValue(1);
            this.selfReportOptionsChanged({value: 1});
          } else {
            this.form?.get('SelfCertificationOptions')?.setValue(2);
            this.selfReportOptionsChanged({value: 2});
          }
        }
        
        this.initialFormValues = JSON.stringify(this.form.getRawValue());
      })
    );
  private readonly destroy$ = new Subject();
  isSaving$ = this.store.select(OrganizationSettingsSelectors.selectSettingsPageIsSaving);
  hasChanges = false;
  initialFormValues: string;
  form: FormGroup;

  limitNumberOfOccurrences: { name: string; value: boolean; }[];
  selfReportOptions: { name: string; value: number; }[];

  constructor(private store: Store, private translationService: TranslationDataService) {
    this.form = new FormGroup({
      SelfCertificationLimitNumberOfOccurrences: new FormControl(),
      SelfCertificationOccurencesPer12Months: new FormControl(),
      SelfCertificationMaxDaysPerOccurence: new FormControl(),
      SelfCertificationMaxDaysPerPeriod: new FormControl(),
      SelfCertificationMaxDaysPerYear: new FormControl(),
      SelfCertificationOptions: new FormControl(),
    });
    this.selfReportOptions = [
      { name: this.translationService.translate('minimum-requirements'), value: 0 },
      { name: this.translationService.translate('ia-agreement'), value: 1 },
      { name: this.translationService.translate('custom'), value: 2 },
    ];
    this.limitNumberOfOccurrences = [
      { name: this.translationService.translate('self-report-days'), value: false },
      { name: this.translationService.translate('self-report-occurrences'), value: true },
    ]
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if (this.initialFormValues && this.initialFormValues !== JSON.stringify(this.form.getRawValue())) {
        this.hasChanges = true;
      } else {
        this.hasChanges = false;
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  selfReportOptionsChanged(event: any) {
    if(event.value == 0) {
      this.form.get('SelfCertificationLimitNumberOfOccurrences')?.setValue(true);
      this.form.get('SelfCertificationOccurencesPer12Months')?.setValue(4);
      this.form.get('SelfCertificationMaxDaysPerOccurence')?.setValue(3);
      this.toggleForm(true);
    } else if(event.value == 1) {
      this.form.get('SelfCertificationLimitNumberOfOccurrences')?.setValue(false);
      this.form.get('SelfCertificationMaxDaysPerPeriod')?.setValue(8);
      this.form.get('SelfCertificationMaxDaysPerYear')?.setValue(24);
      this.toggleForm(true);
    } else {
      this.toggleForm(false);
    }

    this.toggleLimitNumberOfOccurrences();
  }

  toggleForm(disable: boolean) {
    if(disable) {
      this.form?.get('SelfCertificationLimitNumberOfOccurrences')?.disable();
      this.form?.get('SelfCertificationOccurencesPer12Months')?.disable();
      this.form?.get('SelfCertificationMaxDaysPerOccurence')?.disable();
      this.form?.get('SelfCertificationMaxDaysPerPeriod')?.disable();
      this.form?.get('SelfCertificationMaxDaysPerYear')?.disable();
    } else {
      this.form?.get('SelfCertificationLimitNumberOfOccurrences')?.enable();
      this.form?.get('SelfCertificationOccurencesPer12Months')?.enable();
      this.form?.get('SelfCertificationMaxDaysPerOccurence')?.enable();
      this.form?.get('SelfCertificationMaxDaysPerPeriod')?.enable();
      this.form?.get('SelfCertificationMaxDaysPerYear')?.enable();
    }
  }

  toggleLimitNumberOfOccurrences() {
    let model = this.form.value as SelfCertificationSettingsModel;
    this.form?.clearValidators();

    if(model.SelfCertificationOptions == 2) {
      if (model.SelfCertificationLimitNumberOfOccurrences) {
        this.form?.get('SelfCertificationOccurencesPer12Months')?.addValidators([Validators.required]);
        this.form.get('SelfCertificationMaxDaysPerOccurence')?.addValidators([Validators.required]);
        this.setDefaultValues(true);
      } else {
        this.form?.get('SelfCertificationMaxDaysPerPeriod')?.addValidators([Validators.required]);
        this.form.get('SelfCertificationMaxDaysPerYear')?.addValidators([Validators.required]);
        this.setDefaultValues(false);
      }
    }

    this.form?.updateValueAndValidity();
  }

  setDefaultValues(limitNumberOfOccurrences: boolean) {
    if (limitNumberOfOccurrences) {
      this.form.get('SelfCertificationOccurencesPer12Months')?.setValue(4);
      this.form.get('SelfCertificationMaxDaysPerOccurence')?.setValue(3);
    } else {
      this.form.get('SelfCertificationMaxDaysPerPeriod')?.setValue(8);
      this.form.get('SelfCertificationMaxDaysPerYear')?.setValue(24);
    }
  }

  save() {
    let model = this.form.value as SelfCertificationSettingsModel;
    if(model.SelfCertificationOptions == 0) {
      model.SelfCertificationLimitNumberOfOccurrences = true;
      model.SelfCertificationMaxDaysPerOccurence = 3;
      model.SelfCertificationOccurencesPer12Months = 4;
    } else if(model.SelfCertificationOptions == 1) {
      model.SelfCertificationLimitNumberOfOccurrences = false;
      model.SelfCertificationMaxDaysPerPeriod = 8;
      model.SelfCertificationMaxDaysPerYear = 24;
    }
    
    this.store.dispatch(SettingsPageActions.saveSelfCertificationSettings({ settings: model }));
  }

  cancel() {
    this.store.dispatch(SettingsModalActions.CancelModal({ hasChanges: this.hasChanges }));
  }
}
