import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { selectRoutedOvertimeId, selectUrl } from '@mentor-one-ui/core/state/router/router.selectors';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { switchMap, map, catchError, of, exhaustMap, Subject, filter, skipWhile } from 'rxjs';
import { OvertimeApiActions } from '../../overtime/actions/overtime-api.actions';
import { OvertimePageActions, EditOvertimePageActions } from '../../overtime/actions/overtime-page.actions';
import { NewOvertimePageActions } from '../../overtime/containers/new-overtime-page/actions/new-overtime-page.actions';
import { OvertimeModel } from '../../overtime/models/overtime.model';
import {
  selectSelectedOvertimeTypeId,
  selectSelectedOvertimeType,
  selectPageSize,
  selectPage,
  selectFilterByStatus,
  selectFilterByType,
} from '../../overtime/state/overtime.selectors';
import { OvertimeService } from '../../overtime/overtime.service';
import { OvertimeEmployeeListResponseModel } from '../../overtime/models/overtime-employee-list-response.model';
import { FrontpageActions } from '@mentor-one-ui/core/state/frontpage/frontpage.actions';
import { selectSelectedEmployeeIds } from '@mentor-one-ui/time/leave-management/state/leave-administration.selectors';

@Injectable()
export class OvertimeEffects {
  constructor(
    private store: Store,
    private overtimeService: OvertimeService,
    private actions$: Actions,
    private router: Router,
    private translationService: TranslationDataService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  loadOvertimes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FrontpageActions.LoadProcessedTimeBox,
        OvertimePageActions.Enter,
        OvertimePageActions.filterByOvertimeType,
        OvertimePageActions.filterByStatus,
        OvertimePageActions.resetFilter,
        OvertimePageActions.changePage
      ),
      concatLatestFrom(() => [
        this.store.select(UserSelectors.selectSelectedUserId),
        this.store.select(selectPageSize),
        this.store.select(selectPage),
        this.store.select(selectFilterByType),
        this.store.select(selectFilterByStatus),
      ]),
      filter(([action, userId, pageSize, page, typeFilter, statusFilter]) => {
        return userId != null && userId > 0;
      }),
      switchMap(([action, userId, pageSize, page, typeFilter, statusFilter]) => {
        const skip = page * pageSize;
        const take = pageSize;
        return this.overtimeService.getOvertimes(userId!, typeFilter, statusFilter, skip, take).pipe(
          map((result: OvertimeEmployeeListResponseModel) => {
            return OvertimeApiActions.loadOvertimesSuccess({
              overtimes: result.Items,
              totalItems: result.Count,
            });
          }),
          catchError((e) => of(OvertimeApiActions.loadOvertimesError(e.message)))
        );
      })
    )
  );

  registerOvertime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewOvertimePageActions.Register),
      concatLatestFrom(() => this.store.select(UserSelectors.selectSelectedUserId)),
      switchMap(([action, userId]) =>
        this.overtimeService.registerOvertime(userId!, action.overtime).pipe(
          map((overtime: OvertimeModel) => {
            return OvertimeApiActions.registerOvertimeSuccess({
              overtime,
            });
          }),
          catchError((e) =>
            of(
              OvertimeApiActions.registerOvertimeError({
                message: this.translationService.translate('could-not-save-overtime'),
              })
            )
          )
        )
      )
    );
  });

  registerOvertimeCollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewOvertimePageActions.RegisterCollection),
      concatLatestFrom(() => this.store.select(UserSelectors.selectSelectedUserId)),
      switchMap(([action, userId]) =>
        this.overtimeService.registerOvertimeCollection(userId!, action.overtimeCollection).pipe(
          map(() => {
            return OvertimeApiActions.registerOvertimeCollectionSuccess();
          }),
          catchError((e) =>
            of(
              OvertimeApiActions.registerOvertimeCollectionError({
                message: this.translationService.translate('could-not-save-overtime-collection'),
              })
            )
          )
        )
      )
    );
  });

  editOvertime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditOvertimePageActions.UpdateOvertime),
      switchMap((action) =>
        this.overtimeService.updateOvertime(action.overtime).pipe(
          map((overtime: OvertimeModel) => {
            return OvertimeApiActions.UpdateOvertimeSuccess({
              overtime: overtime,
            });
          }),
          catchError((e) =>
            of(
              OvertimeApiActions.UpdateOvertimeError({
                message: this.translationService.translate('could-not-save-overtime'),
              })
            )
          )
        )
      )
    );
  });

  deleteAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditOvertimePageActions.DeleteOvertimeConfirmed),
      concatLatestFrom(() => this.store.select(selectRoutedOvertimeId)),
      switchMap(([action, overtimeId]) =>
        this.overtimeService.deleteOvertime(+overtimeId!).pipe(
          map(() => {
            return OvertimeApiActions.DeleteOvertimeSuccess({
              overtimeId: +overtimeId!,
            });
          }),
          catchError((e) => of(OvertimeApiActions.DeleteOvertimeError(e.message)))
        )
      )
    );
  });

  deleteAbsencePromt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EditOvertimePageActions.DeleteOvertime),
      exhaustMap((action) => {
        const confirmationResult = new Subject<boolean>();

        let message = this.translationService.translate('delete-description');
        if (action.isExported)
          message += '<br><br>' + this.translationService.translate('timeManagement-item-is-exported');

        this.confirmationService.confirm({
          header: this.translationService.translate('delete'),
          message: message,
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: this.translationService.translate('delete'),
          acceptIcon: 'fal fa-trash',
          rejectLabel: this.translationService.translate('cancel'),
          acceptButtonStyleClass: 'p-button-danger',
          rejectButtonStyleClass: 'p-button-secondary',
          accept: () => {
            confirmationResult.next(true);
            confirmationResult.complete();
          },
          reject: () => {
            confirmationResult.next(false);
            confirmationResult.complete();
          },
        });
        return confirmationResult.asObservable();
      }),
      map((result: boolean) => {
        if (result === false) {
          return EditOvertimePageActions.DeleteOvertimeCanceled();
        }
        return EditOvertimePageActions.DeleteOvertimeConfirmed();
      })
    );
  });

  showSuccessToastOnOvertimeCreated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OvertimeApiActions.registerOvertimeSuccess, OvertimeApiActions.registerOvertimeCollectionSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('overtime-registered'),
          });
        })
      );
    },
    { dispatch: false }
  );

  showSuccessToastOnOvertimeUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OvertimeApiActions.UpdateOvertimeSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('overtime-updated'),
          });
        })
      );
    },
    { dispatch: false }
  );

  showSuccessToastOnOvertimeDeleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OvertimeApiActions.DeleteOvertimeSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('overtime-deleted'),
          });
        })
      );
    },
    { dispatch: false }
  );

  navigateOnSave$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OvertimeApiActions.registerOvertimeSuccess, OvertimeApiActions.registerOvertimeCollectionSuccess),
        map((action) => {
          this.router.navigate(['/time/my/overtime']);
        })
      );
    },
    { dispatch: false }
  );

  goBackToOverview$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OvertimeApiActions.DeleteOvertimeSuccess, NewOvertimePageActions.GoBack),
        concatLatestFrom(() => [this.store.select(selectSelectedOvertimeTypeId), this.store.select(selectUrl)]),
        filter(([action, selectedOvertimeTypeId, url]) => selectedOvertimeTypeId === -1 && url.includes('/my/')),
        map((action) => {
          this.router.navigate(['/time/my/overtime']);
        })
      );
    },
    { dispatch: false }
  );

  navigateToOvertimeDetails$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OvertimeApiActions.UpdateOvertimeSuccess, EditOvertimePageActions.GoBack),
        concatLatestFrom(() => this.store.select(selectRoutedOvertimeId)),
        map(([action, absenceId]) => {
          this.router.navigate(['/time/my/overtime/' + absenceId]);
        })
      );
    },
    { dispatch: false }
  );

  goBackToTemplateSelection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewOvertimePageActions.GoBack),
      concatLatestFrom(() => this.store.select(selectSelectedOvertimeType)),
      filter(([action, selectedOvertimeType]) => selectedOvertimeType !== null),
      map((action) => {
        return NewOvertimePageActions.ResetOvertimeTypeSelection();
      })
    );
  });

  editOvertimeErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OvertimeApiActions.UpdateOvertimeError, OvertimeApiActions.registerOvertimeError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('could-not-update-overtime'),
          message: action.message,
        });
      })
    );
  });

  deleteAbsenceErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OvertimeApiActions.DeleteOvertimeError),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('could-not-delete-overtime'),
          message: action.error,
        });
      })
    );
  });

  checkForExistingAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OvertimePageActions.loadConflictingRegistrations),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEmployeeIds),
        this.store.select(UserSelectors.selectSelectedUserId),
      ]),
      filter(([action, userIds, userId]) => {
        if (userIds.length > 0) {
          return userIds.length === 1;
        }
        return userId !== null && userId > 0;
      }),
      switchMap(([action, userIds, userId]) => {
        var id = userIds.length > 0 ? userIds[0] : userId;
        return this.overtimeService.getConflictingRegistrations(id, action.date).pipe(
          map((result) => {
            return OvertimeApiActions.loadConflictingRegistationsSuccess({ registrations: result });
          }),
          catchError((e) => of(OvertimeApiActions.loadConflictingRegistationsError(e.message)))
        );
      })
    )
  );
}
