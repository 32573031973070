import { TimeManagementStatusEnum } from "@mentor-one-ui/time/my-time/absence/models/TimeManagementStatusEnum";

export interface CalendarModel {
  Items: CalendarItemModel[];
  PublicHolidays: PublicHolidayModel[];
}

export interface CalendarItemModel {
  AbsenceId: number;
  EmployeeId: number;
  FromDate: string;
  ToDate: string | null;
  FromTime: string | null;
  ToTime: string | null;
  Title: string;
  EmojiCode: string | null;
  Status: TimeManagementStatusEnum | null;
  CanShowDetails: boolean;
  OrganizationAbsenceMapId: number | null;
  Comment: string | null;
  DaysCalculated: number | null;
  HoursCalculated: string | null;
}

export interface PublicHolidayModel {
  Date: string;
  LocalName: string;
}

export interface GetAbsenceCalendarRequestModel {
  FromDate: Date;
  ToDate: Date;
}

export interface CalendarView {
  type: 'month' | 'week' | 'day';
  size: number;
  eventList: boolean;
  weekNumbers?: boolean;
  resolutionHorizontal: 'day' | 'hour';
}

export interface CalendarCurrentDayDepartmentModel {
  DepartmentId: number | null;
  Employees: CalendarCurrentDayEmployeeModel[];
  TotalPresent: number;
  TotalPresentPartTime: number;
  TotalAbsentAllDay: number;
  TotalAbsentPartOfDay: number;
}

export interface CalendarCurrentDayEmployeeModel {
  EmployeeId: number;
  Status: CalendarCurrentDayEmployeeStatusEnum;
  AbsentHours: CalendarCurrentDayHourModel[];
}

export interface CalendarCurrentDayHourModel {
  FromTime: string;
  ToTime: string;
}

export enum CalendarCurrentDayEmployeeStatusEnum {
  Present = 0,
  AbsentAllDay = 1,
  AbsentPartOfDay = 2,
  PresentPartTimeEmployment = 3
}

export const CALENDAR_VIEW_MONTH: CalendarView = {
  type: 'month',
  size: 1,
  weekNumbers: true,
  eventList: true,
  resolutionHorizontal: 'day'
};

export const CALENDAR_VIEW_WEEK: CalendarView = {
  type: 'week',
  size: 1,
  weekNumbers: true,
  resolutionHorizontal: 'day',
  eventList: true,
};

export const CALENDAR_VIEW_DAY: CalendarView = {
  type: 'day',
  size: 1,
  resolutionHorizontal: 'hour',
  eventList: false,
};
