import { selectUserOrganizationState } from '@mentor-one-ui/user-organization/state';

import { createSelector } from '@ngrx/store';
import { SelfCertificationSettingsModel } from '../../settings/models/self-report-settings.model';

const userOrganizationState = createSelector(selectUserOrganizationState, (state) => state['organization-details']);

const selectSelectedUserOrganization = createSelector(userOrganizationState, (state) => {
  return state.userOrganization;
});

const selectOrganizationRequiresPoliceCertificate = createSelector(selectSelectedUserOrganization, (organization) => {
  return organization?.HrSettings?.PoliceCertificateIsRequired ?? false;
});

const selectOrganizationReqiuresConfidentialityDeclaration = createSelector(
  selectSelectedUserOrganization,
  (organization) => {
    return organization?.HrSettings?.ConfidentialityDeclaration ?? false;
  }
);

const selectDocumentationRequired = createSelector(
  selectOrganizationRequiresPoliceCertificate,
  selectOrganizationReqiuresConfidentialityDeclaration,
  (requirePoliceCertificate, requireConfidentialityDeclaration) => {
    return {
      requirePoliceCertificate: requirePoliceCertificate,
      requireConfidentialityDeclaration: requireConfidentialityDeclaration,
    };
  }
);

const selectSelectedOrganizationName = createSelector(userOrganizationState, (state) => state.userOrganization?.Name);

const selectHideStartupSupportBox = createSelector(
  userOrganizationState,
  (state) => state.userOrganization?.HideSupportBox
);

export const selectAbsenceVisibleToAllEmployees = createSelector(
  selectSelectedUserOrganization,
  (organization) => organization?.HrSettings.AbsenceAreVisibleToAllEmployees
);

const selectSelectedOrganizationImage = createSelector(
  userOrganizationState,
  (state) => state.organizationImage ?? null
);

const selectSelectedOrganizationMap = createSelector(
  userOrganizationState,
  (state) => state.userOrganization?.Map ?? null
);

const selectUserOrganizationStats = createSelector(userOrganizationState, (state) => state.stats);
const selectIsSaving = createSelector(userOrganizationState, (state) => state.isSaving);
const selectIsLoading = createSelector(userOrganizationState, (state) => state.loading);
const selectLoadError = createSelector(userOrganizationState, (state) => state.loadError);

export const selectOrganizationSystemAccess = createSelector(
  selectSelectedUserOrganization,
  (organization) => organization?.SystemAccess
);

export const selectSelfCertificationSettings = createSelector(selectSelectedUserOrganization, (state) => {
  return {
    SelfCertificationLimitNumberOfOccurrences: state?.HrSettings?.SelfCertificationLimitNumberOfOccurrences,
    SelfCertificationOccurencesPer12Months: state?.HrSettings.SelfCertificationOccurencesPer12Months,
    SelfCertificationMaxDaysPerOccurence: state?.HrSettings.SelfCertificationMaxDaysPerOccurence,
    SelfCertificationMaxDaysPerPeriod: state?.HrSettings.SelfCertificationMaxDaysPerPeriod,
    SelfCertificationMaxDaysPerYear: state?.HrSettings.SelfCertificationMaxDaysPerYear,
  } as SelfCertificationSettingsModel;
});

export const organizationDetailsSelectors = {
  selectSelectedUserOrganization,
  selectDocumentationRequired,
  selectSelectedOrganizationName,
  selectSelectedOrganizationImage,
  selectOrganizationImageLoaded: createSelector(userOrganizationState, (state) => state.imageLoaded),
  selectSelectedOrganizationMap,
  selectUserOrganizationStats,
  selectIsSaving,
  selectHideStartupSupportBox,
  selectIsLoading,
  selectLoadError,
};
