import { Action, combineReducers } from '@ngrx/store';
import * as fromReports from '../reports/state';

export const sickLeaveFeatureKey = 'sickLeave';

export interface SickLeaveState {
  [fromReports.featureKey]: fromReports.State;
}

export function reducers(state: SickLeaveState | undefined, action: Action) {
  return combineReducers({
    [fromReports.featureKey]: fromReports.reducer
  })(state, action);
}
