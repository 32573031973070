import { createAction, props } from '@ngrx/store';
import { MentorAdminOrganizationModel } from '@mentor-one-ui/core/models/admin-organization/admin-organization.model';
import { TimeManagementTemplate } from '@mentor-one-ui/user-organization/components/settings/models/time-management-template.model';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';

const LoadOrganizations = createAction('[OrganizationListApi] Load');

const LoadOrganizationsSuccess = createAction(
  '[OrganizationListApi] Load success',
  props<{ organizations: MentorAdminOrganizationModel[] }>()
);

const LoadOrganizationsError = createAction('[OrganizationListApi] Load error', props<{ error: string }>());

export const OrganizationsApiActions = {
  LoadOrganizations,
  LoadOrganizationsError,
  LoadOrganizationsSuccess,
  loadTimeManagementTypesSuccess: createAction(
    '[OrganizationListApi] Load time management types success',
    props<{ timeManagementTypes: TimeManagementTemplate[] }>()
  ),
  loadTimeManagementTypesError: createAction(
    '[OrganizationListApi] Load time management types error',
    props<{ error: string }>()
  ),
  updateTimeManagementTypesSuccess: createAction(
    '[OrganizationListApi] Update time management types success',
    props<{ timeManagementTypes: TimeManagementTemplate[], timeManagementType: TimeManagementType }>()
  ),
  updateTimeManagementTypesError: createAction(
    '[OrganizationListApi] Update time management types error',
    props<{ error: string }>()
  ),
};
