import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { EmployeeAvatarComponent } from '@mentor-one-ui/core/components/employee-avatar/employee-avatar.component';
import { EmployeeModel } from '@mentor-one-ui/core/models/employee/EmployeeModel';
import { PipesModule } from '@mentor-one-ui/core/pipes/pipes.module';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { EmployeePipesModule } from '@mentor-one-ui/employees/pipes/employee-pipes.module';
import { EmployeeListPageActions } from '@mentor-one-ui/employees/state/actions/employee-list-page.actions';
import { EmployeeListSelectors } from '@mentor-one-ui/employees/state/selectors/employee-list.selectors';
import { Store } from '@ngrx/store';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-archived-employees-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    ButtonModule,
    MenuModule,
    EmployeePipesModule,
    PipesModule,
    InputTextModule,
    EmployeeAvatarComponent,
    FormsModule,
  ],
  styleUrl: './archived-employees-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p-table
      #employeeTableArchive
      [value]="(archivedEmployees$ | async)!"
      responsiveLayout="scroll"
      scrollDirection="both"
      [scrollable]="true"
      sortField="ContactInformation.FirstName"
      [globalFilterFields]="[
        'ContactInformation.FirstName',
        'ContactInformation.LastName',
        'ContactInformation.Email',
        'ContactInformation.MobilePhoneNumber'
      ]"
      [paginator]="archivedEmployees.length > 10"
      [rows]="10"
    >
      <ng-template pTemplate="caption">
        <div class="header">
          <span class="p-input-icon-right ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              #texttosearch
              type="text"
              (input)="employeeTableArchive.filterGlobal($any($event.target).value, 'contains')"
              placeholder="{{ 'search' | translate | async }}"
              class="p-inputtext-sm"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th pSortableColumn="ContactInformation.FirstName" field="FirstName">
            {{ 'name' | translate | async }}
            <p-sortIcon field="Name"></p-sortIcon>
          </th>
          <th field="phone">{{ 'phone' | translate | async }}</th>
          <th>{{ 'email' | translate | async }}</th>
          <th pFrozenColumn alignFrozen="right"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-employee>
        <tr>
          <td valign="middle">
            <mo-employee-avatar [employeeId]="employee.EmployeeId"></mo-employee-avatar>
          </td>
          <td class="employee-list-page__linkTd">
            <a
              [routerLink]="['/employees/all', employee.EmployeeId, 'details']"
              class="employee-list-page__linkInTable"
              >{{ employee | employeeFullName }}</a
            >
          </td>
          <td class="employee-list-page__linkTd">
            <a
              *ngIf="employee.ContactInformation.MobilePhoneNumber"
              href="tel:{{ employee.ContactInformation.MobilePhoneNumber }}"
              class="employee-list-page__linkInTable"
            >
              {{ employee.ContactInformation.MobilePhoneNumber }}</a
            >
          </td>
          <td class="employee-list-page__linkTd">
            <a href="mailto:{{ employee.ContactInformation.Email }}" class="employee-list-page__linkInTable">{{
              employee.ContactInformation.Email
            }}</a>
          </td>
          <td pFrozenColumn alignFrozen="right">
            <p-menu
              #employeeMenusArchive
              [model]="employeeMenu"
              appendTo="body"
              [popup]="true"
              (onShow)="buildEmployeeMenuArchive(employee)"
            ></p-menu>
            <button
              pButton
              class="p-button-secondary"
              icon="fal fa-ellipsis-h"
              (click)="employeeMenusArchive.toggle($event)"
              [title]="'edit' | translate | async"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  `,
})
export class ArchivedEmployeesListComponent {
  employeeMenu: MenuItem[] = [];
  archivedEmployees: EmployeeModel[] = [];
  searchText: string = '';
  archivedEmployees$: Observable<EmployeeModel[]>;

  constructor(
    private confirmationService: ConfirmationService,
    private translationService: TranslationDataService,
    private store: Store,
    private router: Router,
  ) {
    this.archivedEmployees$ = this.store.select(EmployeeListSelectors.selectArchivedEmployees).pipe(tap((employees: EmployeeModel[]) => (this.archivedEmployees = employees)));
  }

  buildEmployeeMenuArchive(employee: EmployeeModel) {
    this.employeeMenu = [];
    this.employeeMenu.push(
      {
        label: this.translationService.translate('details'),
        icon: 'fal fa-eye',
        command: () => {
          this.editEmployee(employee);
        },
      },
      {
        label: this.translationService.translate('get-from-archive'),
        icon: 'fal fa-archive',
        command: () => {
          this.UnarchiveEmployee(employee);
        },
      },
      {
        label: this.translationService.translate('delete'),
        icon: 'fal fa-trash',
        command: () => {
          this.deleteEmployee(employee);
        },
      }
    );
  }

  editEmployee(employee: EmployeeModel) {
    this.router.navigate(['/employees/all', employee.EmployeeId, 'details']);
  }

  UnarchiveEmployee(employee: EmployeeModel) {
    this.confirmationService.confirm({
      message: this.translationService.translate('unarchive-description'),
      header: this.translationService.translate('unarchive'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translationService.translate('unarchive'),
      rejectButtonStyleClass: 'p-button-secondary',
      acceptIcon: 'fal fa-heart',
      rejectLabel: this.translationService.translate('cancel'),
      accept: () => {
        this.store.dispatch(EmployeeListPageActions.UnarchiveEmployee({ employee }));
      },
      reject: () => {},
    });
  }

  deleteEmployee(employee: EmployeeModel) {
    this.confirmationService.confirm({
      message: this.translationService.translate('delete-employee-warning'),
      header: this.translationService.translate('delete'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translationService.translate('delete'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      acceptIcon: 'fal fa-trash',
      rejectLabel: this.translationService.translate('cancel'),
      accept: () => {
        this.store.dispatch(EmployeeListPageActions.DeleteEmployee({ employee }));
      },
      reject: () => {},
    });
  }
}
