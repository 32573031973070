import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import {
  AccessSelector,
  selectIsAdministratorPersonal,
  selectIsPersonnelManager,
} from '@mentor-one-ui/core/state/access/access.selector';
import { Subject, takeUntil, combineLatest } from 'rxjs';
import { selectActiveMenuModule } from '@mentor-one-ui/core/state/application/application.selectors';
import { selectUrl } from '@mentor-one-ui/core/state/router/router.selectors';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit, OnDestroy {
  @Output() closeMenu = new EventEmitter();

  mainMenu: MenuItem[];
  isSystemAdmin: boolean | undefined;
  isPersonellManager: boolean | undefined;
  isAdministratorPersonal: boolean | undefined;
  orgId: number | undefined;
  activeMenuModule: string | undefined;
  public ngDestroyed$ = new Subject();
  selectUrl: string;

  constructor(
    public dialogService: DialogService,
    private store: Store,
    private translationDataService: TranslationDataService
  ) {
    this.translationDataService.languageChanged.subscribe(() => {
      this.CreateUpdatedMenu();
    });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(null);
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(selectActiveMenuModule),
      this.store.select(AccessSelector.selectIsSystemAdmin),
      this.store.select(selectIsPersonnelManager),
      this.store.select(selectIsAdministratorPersonal),
      this.store.select(selectUrl),
    ])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([activeMenuModule, isSystemAdmin, isPersonellManager, isAdministratorPersonal, selectUrl]) => {
        let updateMenu = false;
        if (
          this.isSystemAdmin !== isSystemAdmin ||
          this.isPersonellManager !== isPersonellManager ||
          this.isAdministratorPersonal !== isAdministratorPersonal
        ) {
          updateMenu = true;
        }
        this.activeMenuModule = activeMenuModule;
        this.isSystemAdmin = isSystemAdmin;
        this.isPersonellManager = isPersonellManager;
        this.isAdministratorPersonal = isAdministratorPersonal;
        this.selectUrl = selectUrl;
        if (updateMenu) {
          this.CreateUpdatedMenu();
        }
      });
  }

  CreateUpdatedMenu() {
    this.mainMenu = [
      {
        icon: 'fal fa-home',
        label: this.translationDataService.translate('home'),
        routerLink: '/',
        routerLinkActiveOptions: { exact: true },
        visible: !this.isSystemAdmin,
        command: () => {
          this.closeMenu.emit();
        },
      },
      {
        icon: 'fal fa-users',
        label: this.translationDataService.translate('employees'),
        routerLink: '/employees/all',
        visible: !this.isSystemAdmin && !(this.isAdministratorPersonal || this.isPersonellManager), //Vis denne hvis vanlig ansatt
        routerLinkActiveOptions: { exact: false },
        command: () => {
          this.closeMenu.emit();
        },
      },
      {
        icon: 'fal fa-users',
        label: this.translationDataService.translate('employees'),
        expanded: this.activeMenuModule == 'employees',
        visible: !this.isSystemAdmin && (this.isAdministratorPersonal || this.isPersonellManager), //Vis denne hvis admin/personalleder
        items: [
          {
            icon: 'fal fa-users',
            label: this.translationDataService.translate('employees'),
            routerLink: '/employees/all',
            visible: !this.isSystemAdmin,
            routerLinkActiveOptions: { exact: false },
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-list',
            label: this.translationDataService.translate('lists'),
            routerLink: '/employees/lists',
            visible: !this.isSystemAdmin,
            routerLinkActiveOptions: { exact: false },
            command: () => {
              this.closeMenu.emit();
            },
          },
        ]
      },
      {
        label: this.translationDataService.translate('the-organization'),
        expanded: this.activeMenuModule == 'organization' && !this.selectUrl?.includes('settings'),
        icon: 'fal fa-sitemap',
        visible: !this.isSystemAdmin,

        items: [
          {
            icon: 'fal fa-info-circle',
            label: this.translationDataService.translate('about-organization'),
            routerLink: '/organization/about',
            visible: !this.isSystemAdmin,
            routerLinkActiveOptions: { exact: false },
            
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-address-book',
            label: this.translationDataService.translate('contact-list'),
            routerLink: '/organization/contacts',
            routerLinkActiveOptions: { exact: true },
            visible: !this.isSystemAdmin,
            command: () => {
              this.closeMenu.emit();
            },
          },
        ],
      },
      {
        label: this.translationDataService.translate('time'),
        expanded: this.activeMenuModule == 'time',
        icon: 'fal fa-clock',
        visible: !this.isSystemAdmin,
        items: [
          {
            icon: 'fal fa-clock',
            label: this.translationDataService.translate('my-time'),
            routerLink: '/time/my',
            routerLinkActiveOptions: { exact: false },
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-user-clock',
            label: this.translationDataService.translate('manage-employees-time-menu-title'),
            routerLink: '/time/manage',
            routerLinkActiveOptions: { exact: false },
            visible: this.isPersonellManager || this.isAdministratorPersonal,
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-calendar',
            label: this.translationDataService.translate('calendar'),
            routerLink: '/time/calendar',
            routerLinkActiveOptions: { exact: true },
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-clock',
            label: this.translationDataService.translate('balance'),
            routerLink: '/time/balance',
            routerLinkActiveOptions: { exact: false },
            visible: this.isPersonellManager || this.isAdministratorPersonal,
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-sack-dollar',
            label: this.translationDataService.translate('payroll'),
            routerLink: '/time/payroll',
            routerLinkActiveOptions: { exact: false },
            visible: this.isAdministratorPersonal,
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-table',
            label: this.translationDataService.translate('reports'),
            routerLink: '/time/reports',
            routerLinkActiveOptions: { exact: true },
            visible: this.isAdministratorPersonal || this.isPersonellManager,
            command: () => {
              this.closeMenu.emit();
            },
          },
        ],
      },
      {
        label: this.translationDataService.translate('sick-leave'),
        expanded: this.activeMenuModule == 'sick-leave',
        icon: 'fal fa-notes-medical',
        visible: !this.isSystemAdmin && (this.isAdministratorPersonal || this.isPersonellManager),
        items: [
          {
            icon: 'fal fa-list',
            label: this.translationDataService.translate('sick-leave-mandatory-statistics'),
            routerLink: '/sick-leave/report',
            visible: !this.isSystemAdmin && (this.isAdministratorPersonal || this.isPersonellManager),
            routerLinkActiveOptions: { exact: false },
            command: () => {
              this.closeMenu.emit();
            },
          },
          {
            icon: 'fal fa-chart-bar',
            label: this.translationDataService.translate('sick-leave-extended-statistics'),
            routerLink: '/sick-leave/statistics',
            visible: !this.isSystemAdmin,
            routerLinkActiveOptions: { exact: false },
            command: () => {
              this.closeMenu.emit();
            },
          },
        ],
      },
      {
        icon: 'fal fa-cog',
        label: this.translationDataService.translate('settings'),
        routerLink: '/organization/settings/absence',
        routerLinkActiveOptions: { exact: true },
        expanded: this.selectUrl?.startsWith('/organization/settings/'),
        visible: !this.isSystemAdmin && this.isAdministratorPersonal,
        command: () => {
          this.closeMenu.emit();
        },
      },
      {
        icon: 'fal fa-sitemap',
        label: this.translationDataService.translate('organizations'),
        routerLink: '/mentor-admin/organizations',
        routerLinkActiveOptions: { exact: true },
        visible: this.isSystemAdmin,
        command: () => {
          this.closeMenu.emit();
        },
      },
      {
        icon: 'fal fa-users',
        label: this.translationDataService.translate('employees'),
        routerLink: '/mentor-admin/employees',
        routerLinkActiveOptions: { exact: true },
        visible: this.isSystemAdmin,
        command: () => {
          this.closeMenu.emit();
        },
      },
      {
        icon: 'fal fa-exclamation-triangle',
        label: this.translationDataService.translate('system-messages'),
        routerLink: '/mentor-admin/system-messages',
        routerLinkActiveOptions: { exact: true },
        visible: this.isSystemAdmin,
        command: () => {
          this.closeMenu.emit();
        },
      },
    ];
  }
}
