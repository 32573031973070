import { HttpErrorResponse } from '@angular/common/http';
import { SystemMessageModel } from '@mentor-one-ui/core/models/system-messages.model';
import { createAction, props } from '@ngrx/store';

export const ApplicationActions = {
  SetActiveThemeMode: createAction('[Application] Set Active Theme Mode'),
  SwitchThemeMode: createAction('[Application] Set Theme Mode', props<{ darkmode: boolean }>()),
  DiscardUnsavedChanges: createAction('[Application] Discard Unsaved Changes'),
  SuccessMessage: createAction('[Application] Success Message', props<{ title: string; message: string }>()),
  ErrorMessage: createAction('[Application] Error Message', props<{ title: string; message: string }>()),
  InfoMessage: createAction('[Application] Info Message', props<{ title: string; message: string }>()),
  SetActiveMenuModule: createAction('[Application] Set Active Menu Module', props<{ activeMenuModule: string }>()),
  OpenOrganizationSelectModal: createAction('[Application] Open Organization Modal'),
  CloseOrganizationSelectModal: createAction('[Application] Close Organization Modal'),
};
