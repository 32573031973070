import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserSelectors } from '@mentor-one-ui/core/state/user/user.selector';
import { MediaQuery } from '../../services/media-queries';
import { MenuItem } from 'primeng/api/menuitem';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { UserActions } from '@mentor-one-ui/core/state/user/user.actions';
import { UserOrganizationModel } from '@mentor-one-ui/core/models/user-organization/user-organization.model';
import { TranslationActions } from '@mentor-one-ui/core/state/translations/translation.actions';
import { ThemeService } from '@mentor-one-ui/core/services/theme.service';
import { ApplicationActions } from '@mentor-one-ui/core/state/application/application.actions';
import { selectIsDarkMode } from '@mentor-one-ui/core/state/application/application.selectors';
import * as _ from 'underscore';
import { GroupOrganizationTypeEnum } from '@mentor-one-ui/core/models/enums';
import { logout } from '@mentor-one-ui/core/state/auth/auth.actions';
import { AccessSelector } from '@mentor-one-ui/core/state/access/access.selector';

interface User {
  name: string;
  code: string;
}

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  title = 'Mentor Personal';

  availableOrganizations$: Observable<UserOrganizationModel[]>;
  userMenuItems: MenuItem[];
  availableOrganizationsMenu: MenuItem[];
  currentUserName$: Observable<string | undefined>;
  visibleMainNavigation: boolean = false;
  currentOrganizationId: number | undefined;
  currentOrganizationName: string | any;
  selectedCultureCode: string | null;
  darkMode: boolean = false;
  organizations: UserOrganizationModel[];
  isSysadmin: Observable<boolean> = this.store.select(AccessSelector.selectIsSystemAdmin);

  constructor(
    private themeService: ThemeService,
    private store: Store,
    private translationDataService: TranslationDataService
  ) {
    translationDataService.languageChanged.subscribe(() => this.createUserMenu());
    this.store.select(UserSelectors.selectUserCultureCode).subscribe((cultureCode) => {
      this.selectedCultureCode = cultureCode;
    });

    this.createAvailableOrganizationsMenu();
  }

  ngOnInit() {
    this.currentUserName$ = this.store.select(UserSelectors.selectSelectedUserFullName);

    this.store.select(UserSelectors.selectCurrentOrganizationId).subscribe((x) => {
      this.currentOrganizationId = x;
    });
    this.store.select(UserSelectors.selectCurrentOrganizationName).subscribe((x) => {
      this.currentOrganizationName = x;
    });
    this.createUserMenu();

    this.store.select(selectIsDarkMode).subscribe((darkmode) => {
      this.darkMode = darkmode;
    });

    this.store.select(UserSelectors.selectAvailabeOrganziations).subscribe((x) => {
      this.organizations = x;
    });
  }

  switchOrganization(event: any) {
    this.store.dispatch(UserActions.switchOrganzation({ organizationId: event.value }));
  }

  showMenu() {
    return MediaQuery.screenSize === 'small' || MediaQuery.screenSize === 'xsmall';
  }

  xsmallScreen() {
    return MediaQuery.screenSize === 'xsmall';
  }

  logout() {
    this.store.dispatch(logout());
  }

  showMenuModal() {
    this.store.dispatch(ApplicationActions.OpenOrganizationSelectModal());
  }

  swap() {
    if (this.selectedCultureCode === 'nb-NO') {
      this.store.dispatch(UserActions.setCultureCode({ cultureCode: 'en-US' }));
      this.store.dispatch(TranslationActions.selectLanguage({ cultureCode: 'en-US' }));
    } else {
      this.store.dispatch(UserActions.setCultureCode({ cultureCode: 'nb-NO' }));
      this.store.dispatch(TranslationActions.selectLanguage({ cultureCode: 'nb-NO' }));
    }
  }
  createUserMenu() {
    this.userMenuItems = [
      {
        label: this.translationDataService.translate('my-profile'),
        icon: 'fal fa-address-card',
        routerLink: '/my-profile',
      },
      {
        label: this.translationDataService.translate('my-tasks'),
        icon: 'fal fa-tasks',
        disabled: true,
        visible: false,
      },
      {
        label: `EN/NO`,
        icon: 'fal fa-globe',
        command: () => {
          this.swap();
        },
      },
      {
        label: 'Darkmode',
        visible: false,
        icon: this.darkModeIcon(),
        command: () => {
          this.toggleDarkmode();
        },
      },
      {
        separator: true,
      },
      {
        label: this.translationDataService.translate('logout'),
        icon: 'fal fa-sign-out',
        command: () => {
          this.logout();
        },
      },
    ];
  }

  menuItemGenerator(data: UserOrganizationModel) {
    return {
      label: data.DisplayName,
      icon: data.OrganizationId == 1 ? 'fal fa-city' : data.HasChildren ? 'fal fa-building' : 'fal fa-home',
      isSupportLogin: data.IsSupportLogin,
      expires: data.Expires,
      organizationId: data.OrganizationId,
      command: () => {
        this.switchOrganization({ value: data.OrganizationId });
      },
    };
  }

  closeMenu() {
    this.visibleMainNavigation = false;
  }

  getIcon(org: UserOrganizationModel | undefined): string {
    if (org?.OrganizationId === 1) {
      return 'fal fa-city';
    }

    switch (org?.GroupOrganizationType) {
      case GroupOrganizationTypeEnum.GroupOrganization:
      case GroupOrganizationTypeEnum.ServiceProvider:
        return 'fal fa-building';
      case GroupOrganizationTypeEnum.Organization:
        return 'fal fa-home';
      default:
        return 'pi pi-question';
    }
  }

  flattenOrganizationTree(organization: UserOrganizationModel): UserOrganizationModel[] {
    let flattenedOrganizations: UserOrganizationModel[] = [];
    let org = { ...organization };
    org.HasChildren =
      organization.ChildOrganizations.length > 0 &&
      (organization.GroupOrganizationType == GroupOrganizationTypeEnum.GroupOrganization ||
        organization.GroupOrganizationType == GroupOrganizationTypeEnum.ServiceProvider);
    flattenedOrganizations.push(org);
    organization.ChildOrganizations.forEach((childOrg) => {
      flattenedOrganizations = flattenedOrganizations.concat(this.flattenOrganizationTree(childOrg));
    });

    return flattenedOrganizations;
  }

  useComplexMenu() {
    if (this.isSysadmin) return true;
    return this.availableOrganizationsMenu?.length > 6;
  }

  createAvailableOrganizationsMenu() {
    let tempValue: MenuItem[] = [];
    this.organizations?.forEach((org) => {
      let childrenItems: UserOrganizationModel[] = [];
      childrenItems = this.flattenOrganizationTree(org);

      childrenItems.forEach((childOrg) => {
        tempValue.push(this.menuItemGenerator(childOrg));
      });
    });

    let sortedArray = _.chain(tempValue)
      .sortBy('label')
      .sortBy((s) => {
        if (s.organizationId == 1) return 1;

        if (s.isSupportLogin) return 2;

        return 3;
      })
      .value();

    this.availableOrganizationsMenu = sortedArray;
  }

  toggleDarkmode() {
    this.darkMode = !this.darkMode;
    let theme = this.darkMode ? 'pbl-dark' : 'pbl-light';
    this.store.dispatch(ApplicationActions.SwitchThemeMode({ darkmode: this.darkMode }));
    this.themeService.switchTheme(theme);
    this.createUserMenu();
  }
  darkModeIcon() {
    return this.darkMode ? 'fal fa-sun' : 'fal fa-moon';
  }

  changeTheme(theme: string) {
    this.themeService.switchTheme(theme);
  }
}
