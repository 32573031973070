import { Injectable } from '@angular/core';
import { EmployeeService } from '@mentor-one-ui/core/services/api/employee.service';
import { OrganizationService } from '@mentor-one-ui/core/services/api/organization.service';
import { TranslationDataService } from '@mentor-one-ui/core/services/translation-data.service';
import { FrontpageService } from '@mentor-one-ui/frontpage/frontpage.service';
import { CreateNewAbsenceModalComponent } from '@mentor-one-ui/time/calendar/components/create-new-absence-modal/create-new-absence-modal.component';
import { CalendarCurrentDayDepartmentModel } from '@mentor-one-ui/time/calendar/models/calendar.models';
import { RejectLeaveDialogComponent } from '@mentor-one-ui/time/leave-management/components/reject-leave-dialog/reject-leave-dialog.component';
import { AbsenceManageService } from '@mentor-one-ui/time/my-time/absence/absence-manage.service';
import { AbsenceModel } from '@mentor-one-ui/time/my-time/absence/models/absence.model';
import { TimeFilter } from '@mentor-one-ui/time/shared/time-filter.model';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of, map, switchMap, catchError, filter } from 'rxjs';
import { UserSelectors } from '../user/user.selector';
import { FrontPageApiActions, FrontpageActions } from './frontpage.actions';
import { TimeManagementType } from '@mentor-one-ui/time/my-time/absence/models/TimeManagementType';
import { OvertimeModel } from '@mentor-one-ui/time/my-time/overtime/models/overtime.model';
import { ApplicationActions } from '../application/application.actions';
import { TimeManagementListSortingEnum } from '@mentor-one-ui/time/leave-management/models/time-management-list-sorting.model';

@Injectable()
export class FrontpageEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dialogService: DialogService,
    private translationService: TranslationDataService,
    private organizationService: OrganizationService,
    private absenceManageService: AbsenceManageService,
    private frontpageService: FrontpageService,
    private messageService: MessageService,
    private employeeService: EmployeeService
  ) {}

  dialogRef: DynamicDialogRef;

  dialogClosed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FrontpageActions.CloseRegistrationModal),
        map((payload) => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
        })
      ),
    { dispatch: false }
  );

  enterApplication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FrontpageActions.Enter),
      switchMap((action) => {
        return this.organizationService.getSystemMessageList().pipe(
          map((result) => {
            return FrontPageApiActions.LoadSystemMessageListSuccess({ systemMessageList: result });
          }),
          catchError((e) => of(FrontPageApiActions.LoadSystemMessageListError(e.message)))
        );
      })
    );
  });

  openNewRegistrationModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FrontpageActions.OpenNewRegistrationModal),
        switchMap((action) => {
          this.dialogRef = this.dialogService.open(CreateNewAbsenceModalComponent, {
            header: this.translationService.translate('new-registration'),
            width: '20rem',
            styleClass: 'create-new-absence-modal',
            closable: true,
          });

          return this.dialogRef.onClose;
        })
      ),
    { dispatch: false }
  );

  showToastOnConfirmRejection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FrontPageApiActions.RejectAbsenceSuccess, FrontPageApiActions.RejectOvertimeSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('registration-rejected'),
          });
        })
      ),
    { dispatch: false }
  );

  loadManagedAbsences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FrontpageActions.LoadManageListFrontpage,
        FrontPageApiActions.ApproveAbsenceSuccess,
        FrontPageApiActions.RejectAbsenceSuccess,
        FrontPageApiActions.ApproveOvertimeSuccess,
        FrontPageApiActions.RejectOvertimeSuccess
      ),
      switchMap((action) => {
        let filter: TimeFilter = {
          selectedDepartmentIds: [],
          selectedPositions: [],
          overtimeMapdIds: [],
          absenceMapIds: [],
          selectedSortOptionId: TimeManagementListSortingEnum.RegistrationDateDescending,
          selectedEmployeeId: -1,
          sortOptions: [],
          statuses: [],
          employeeSearch: '',
          fromDate: null,
          toDate: null,
        };

        return this.absenceManageService.getAbsences(filter, 0, [0], true, 3).pipe(
          map((result) => {
            return FrontPageApiActions.LoadManageListFrontpageSuccess({ manageList: result });
          }),
          catchError((e) => of(FrontPageApiActions.LoadManageListFrontpageError(e.message)))
        );
      })
    );
  });

  CloseEmployeeSupportBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.CloseEmployeeSupportBox),
      concatLatestFrom(() => this.store.select(UserSelectors.selectSelectedUserId)),
      switchMap(([action, employeeId]) =>
        this.employeeService.hideSupportBoxForEmployee(employeeId!).pipe(
          map(() => {
            return FrontPageApiActions.CloseEmployeeSupportBoxSuccess();
          }),
          catchError((e) => of(FrontPageApiActions.CloseEmployeeSupportBoxError(e.message)))
        )
      )
    )
  );

  closeStartupSupportBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.CloseStartupSupportBox),
      switchMap((action) =>
        this.organizationService.CloseSupportBoxOnFrontpage().pipe(
          map(() => {
            return FrontPageApiActions.CloseStartupSupportBoxSuccess();
          }),
          catchError((e) => of(FrontPageApiActions.CloseStartupSupportBoxError(e.message)))
        )
      )
    )
  );

  loadDailySummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.Enter, FrontPageApiActions.ApproveAbsenceSuccess),
      switchMap((action) =>
        this.frontpageService.GetTodaySummary().pipe(
          map((result: CalendarCurrentDayDepartmentModel[]) => {
            return FrontpageActions.LoadDailySummarySuccess({ summary: result });
          }),
          catchError((e) => of(FrontpageActions.LoadDailySummaryError(e.message)))
        )
      )
    )
  );

  approveAbsenceFromFrontPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.ApproveLeaveRequest),
      switchMap((action) =>
        this.absenceManageService.approveAbsence(action.absenceId).pipe(
          map((absence: AbsenceModel) => {
            return FrontPageApiActions.ApproveAbsenceSuccess();
          }),
          catchError((e) =>
            of(
              FrontPageApiActions.ApproveAbsenceError({
                error: this.translationService.translate('could-not-approve-registration'),
              })
            )
          )
        )
      )
    )
  );
  approveOvertimeFromFrontPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.ApproveOvertimeRequest),
      switchMap((action) =>
        this.absenceManageService.approveOvertime(action.overtimeId).pipe(
          map(() => {
            return FrontPageApiActions.ApproveOvertimeSuccess();
          }),
          catchError((e) =>
            of(
              FrontPageApiActions.ApproveOvertimeError({
                error: this.translationService.translate('could-not-approve-registration'),
              })
            )
          )
        )
      )
    )
  );

  showErrorToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FrontPageApiActions.ApproveOvertimeError,
        FrontPageApiActions.ApproveAbsenceError,
        FrontPageApiActions.RejectAbsenceError,
        FrontPageApiActions.RejectOvertimeError
      ),
      map((action) => {
        return ApplicationActions.ErrorMessage({
          title: this.translationService.translate('something-went-wrong'),
          message: action.error,
        });
      })
    );
  });

  showToastOnConfirm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FrontPageApiActions.ApproveAbsenceSuccess, FrontPageApiActions.ApproveOvertimeSuccess),
        map((action) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translationService.translate('success'),
            detail: this.translationService.translate('registration-approved'),
          });
        })
      ),
    { dispatch: false }
  );

  showRejectAbsenceModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.ShowRejectAbsenceDialog),
      switchMap((action) => {
        this.dialogRef = this.dialogService.open(RejectLeaveDialogComponent, {
          data: {
            model: action.model,
          },
          closeOnEscape: false,
          closable: false,
        });

        return this.dialogRef.onClose;
      }),
      map((data) => {
        console.log(data);
        if (data && data.save) {
          return FrontpageActions.RejectLeaveRequest({
            leaveId: data.leaveId,
            leaveTypeId: data.leaveTypeId,
            comment: data.comment,
          });
        } else {
          return FrontpageActions.CancelRejectAbsenceRequest();
        }
      })
    )
  );

  confirmRejectAbsence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.RejectLeaveRequest),
      filter((action) => action.leaveTypeId == TimeManagementType.Absence),
      switchMap((action) =>
        this.absenceManageService.rejectAbsence(action.leaveId, action.comment).pipe(
          map((absence: AbsenceModel) => {
            return FrontPageApiActions.RejectAbsenceSuccess();
          }),
          catchError((e) =>
            of(
              FrontPageApiActions.RejectAbsenceError({
                error: this.translationService.translate('could-not-reject-registration'),
              })
            )
          )
        )
      )
    )
  );

  confirmRejectOvertime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrontpageActions.RejectLeaveRequest),
      filter((action) => action.leaveTypeId == TimeManagementType.Overtime),
      switchMap((action) =>
        this.absenceManageService.rejectOvertime(action.leaveId, action.comment).pipe(
          map((overtime: OvertimeModel) => {
            return FrontPageApiActions.RejectOvertimeSuccess();
          }),
          catchError((e) =>
            of(
              FrontPageApiActions.RejectOvertimeError({
                error: this.translationService.translate('could-not-reject-registration'),
              })
            )
          )
        )
      )
    )
  );
}
